import store from '@/store'

export function countTagDataByDir(dir, data, xyReverse) {
  let req = {
    recordLastTag: 0,
    tagItemArr: null,
  }
  let tagItemArr = JSON.parse(JSON.stringify(data))
  if (dir == 1) {
    req.tagItemArr = tagItemArr
    let lastParts = tagItemArr[tagItemArr.length - 1].parts
    req.recordLastTag = lastParts[lastParts.length - 1].plankNum
  } else if (dir == 2) {
    tagItemArr.forEach((item) => {
      let parts = item.parts
      let len = parts.length - 1
      let mid
      for (let i = 0; i < len; i++) {
        for (let j = 0; j < len; j++) {
          let newWidth = xyReverse ? parts[j].rect.height : parts[j].rect.width
          let newStartX = xyReverse
            ? parts[j].startY
            : parts[j].startX + parts[j].rect.width
          let newNextStartX = xyReverse
            ? parts[j + 1].startY
            : parts[j + 1].startX + parts[j + 1].rect.width
          let newNextStartY = xyReverse
            ? item.plankWidth - parts[j + 1].startX - newWidth
            : parts[j + 1].startY
          let newStartY = xyReverse
            ? item.plankWidth - parts[j].startX - newWidth
            : parts[j].startY

          let Y = newStartY
          let nextY = newNextStartY
          let X = newStartX
          let nextX = newNextStartX
          if (Y > nextY) {
            mid = parts[j]
            parts[j] = parts[j + 1]
            parts[j + 1] = mid
          }
          if (Y == nextY) {
            if (X > nextX) {
              mid = parts[j]
              parts[j] = parts[j + 1]
              parts[j + 1] = mid
            }
          }
        }
      }
      for (let i = 0; i < len; i++) {
        for (let j = 0; j < len; j++) {
          let newWidth = xyReverse ? parts[j].rect.height : parts[j].rect.width
          let newStartX = xyReverse
            ? parts[j].startY
            : parts[j].startX + parts[j].rect.width
          let newNextStartX = xyReverse
            ? parts[j + 1].startY
            : parts[j + 1].startX + parts[j + 1].rect.width
          let newNextStartY = xyReverse
            ? item.plankWidth - parts[j + 1].startX - newWidth
            : parts[j + 1].startY
          let newStartY = xyReverse
            ? item.plankWidth - parts[j].startX - newWidth
            : parts[j].startY
          let Y = newStartY
          let nextY = newNextStartY
          let X = newStartX
          let nextX = newNextStartX
          if (X == nextX) {
            if (Y > nextY) {
              mid = parts[j]
              parts[j] = parts[j + 1]
              parts[j + 1] = mid
            }
          }
        }
      }
    })
    let partsIndex = 1
    tagItemArr.forEach((item) => {
      item.parts.forEach((e) => {
        e.partsIndex = partsIndex
        partsIndex += 1
      })
    })
    req.tagItemArr = tagItemArr
    let lastParts = req.tagItemArr[tagItemArr.length - 1].parts
    req.recordLastTag = lastParts[lastParts.length - 1].plankNum
  } else if (dir == 3) {
    tagItemArr.forEach((item) => {
      let parts = item.parts
      let len = parts.length - 1
      let mid
      for (let i = 0; i < len; i++) {
        for (let j = 0; j < len; j++) {
          let newWidth = xyReverse ? parts[j].rect.height : parts[j].rect.width
          let newStartX = xyReverse
            ? parts[j].startY
            : parts[j].startX + parts[j].rect.width
          let newNextStartX = xyReverse
            ? parts[j + 1].startY
            : parts[j + 1].startX + parts[j + 1].rect.width
          let newNextStartY = xyReverse
            ? item.plankWidth - parts[j + 1].startX - newWidth
            : parts[j + 1].startY + parts[j + 1].rect.height
          let newStartY = xyReverse
            ? item.plankWidth - parts[j].startX - newWidth
            : parts[j].startY + parts[j].rect.height
          let Y = newStartY
          let nextY = newNextStartY
          let X = newStartX
          let nextX = newNextStartX
          if (Y < nextY) {
            mid = parts[j]
            parts[j] = parts[j + 1]
            parts[j + 1] = mid
          }
          if (Y == nextY) {
            if (X < nextX) {
              mid = parts[j]
              parts[j] = parts[j + 1]
              parts[j + 1] = mid
            }
          }
        }
      }
      for (let i = 0; i < len; i++) {
        for (let j = 0; j < len; j++) {
          let newWidth = xyReverse ? parts[j].rect.height : parts[j].rect.width
          let newStartX = xyReverse
            ? parts[j].startY
            : parts[j].startX + parts[j].rect.width
          let newNextStartX = xyReverse
            ? parts[j + 1].startY
            : parts[j + 1].startX + parts[j + 1].rect.width
          let newNextStartY = xyReverse
            ? item.plankWidth - parts[j + 1].startX - newWidth
            : parts[j + 1].startY + parts[j + 1].rect.height
          let newStartY = xyReverse
            ? item.plankWidth - parts[j].startX - newWidth
            : parts[j].startY + parts[j].rect.height

          let Y = newStartY
          let nextY = newNextStartY
          let X = newStartX
          let nextX = newNextStartX
          if (X == nextX) {
            if (Y < nextY) {
              mid = parts[j]
              parts[j] = parts[j + 1]
              parts[j + 1] = mid
            }
          }
        }
      }
    })
    let partsIndex = 1
    tagItemArr.forEach((item) => {
      item.parts.forEach((e) => {
        e.partsIndex = partsIndex
        partsIndex += 1
      })
    })
    req.tagItemArr = tagItemArr
    let lastParts = req.tagItemArr[tagItemArr.length - 1].parts
    req.recordLastTag = lastParts[lastParts.length - 1].plankNum
  }
  return req
}
export function JxTagData(data) {
  let tagItemArrCopy = JSON.parse(JSON.stringify(data))
  tagItemArrCopy.forEach((item) => {
    const { plankWidth, plankHeight } = item
    item.parts.forEach((e) => {
      store.state.ncSetting.xyReverse
        ? (e.startY = plankHeight - e.startY - e.rect.height)
        : (e.startX = plankWidth - e.startX - e.rect.width)
      if (e.path) {
        if (store.state.ncSetting.xyReverse) {
          e.path[0] = e.path[0].map((point) => {
            point.y = e.rect.height - point.y
            return point
          })
        } else {
          e.path[0] = e.path[0].map((point) => {
            point.x = e.rect.width - point.x
            return point
          })
        }
      }
    })
  })
  return tagItemArrCopy
}
