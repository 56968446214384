import ClipperLib from '../util/clipper_unminified'

// import {changePositionCutOrigin} from '../util/LayoutTool'
// import { layoutGap, plankEdgeOff, cutKnifeRadius, startPosition, keepDecimalNumBySetting, ncSettings } from './LayoutFuncs.js'
export function change_plank_dir(
  plank,
  setting,
  bigPlankWidth,
  bigPlankHeight
) {
  // plank = JSON.parse(JSON.stringify(plank))
  // let tmpValue = layoutGap - (plankEdgeOff - cutKnifeRadius) * 2
  // let tmpValue2 = 0
  // let isSurplus = false;
  // let surplusMargin = setting.panelSize.surplusMargin
  // let surplusInfo = plank['surplusInfo']
  // if (surplusInfo && surplusInfo.hasOwnProperty("isSurplus")) {
  //     isSurplus = surplusInfo["isSurplus"]
  // }
  // if (isSurplus && !surplusMargin) {
  //     tmpValue = -plankEdgeOff;
  //     tmpValue2 = -plankEdgeOff
  // }
  // tmpValue = -cutKnifeRadius;
  // tmpValue2 = -cutKnifeRadius
  // changePositionCutOrigin(plank, tmpValue, tmpValue2, bigPlankWidth, bigPlankHeight);
  let _dir = setting.output56FDrillSlotDir
  let outline_dir = setting.output56FDrillLayoutDir
  let is_output56FDrillLayoutDir = setting.is_output56FDrillLayoutDir ?? false
  let xyReverse = setting.xyReverse
  let selected_dir = setting.selectedOutput56DrillSoltDir
  const is_front_edge_fixed = setting.is_front_edge_fixed
  const long_edge_rotate_range = setting.long_edge_rotate_range ?? 1200
  const front_edge_fixed_direction = setting.front_edge_fixed_direction
  const model_front_edge = plank.model_front_edge ?? ''
  const edge_dir_map = {
    '←': 2,
    '↓': 3,
    '→': 0,
    '↑': 1,
    left: 2,
    front: 3,
    right: 0,
    back: 1,
  }
  plank = JSON.parse(JSON.stringify(plank))
  // if(xyReverse) {
  //     let oPath = plank["oPath"];
  //     let slots = plank['slots']
  //     if(oPath) {
  //         for(var pi in oPath) {
  //             for(var pj in oPath[pi]) {
  //                 oPath[pi][pj] = {
  //                   x: oPath[pi][pj]["y"],
  //                   y: oPath[pi][pj]["x"]
  //                 }
  //             }
  //         }
  //     }
  //     var tmp = plank["startX"];
  //     plank["startX"] = plank["startY"];
  //     plank["startY"] = tmp;
  //     plank["oRect"] = {
  //       x: 0,
  //       y: 0,
  //       width: plank["oRect"]["height"],
  //       height: plank["oRect"]["width"]
  //     };
  //     for(var si in slots) {
  //         var slot = slots[si];
  //         slot["side"] *= -1;
  //         slot["pt1"] = {
  //           x: slot["pt1"]["y"],
  //           y: slot["pt1"]["x"]
  //         };
  //         slot["pt2"] = {
  //           x: slot["pt2"]["y"],
  //           y: slot["pt2"]["x"]
  //         }
  //         slot["opt1"] = {
  //           x: slot["opt1"]["y"],
  //           y: slot["opt1"]["x"]
  //         };
  //         slot["opt2"] = {
  //           x: slot["opt2"]["y"],
  //           y: slot["opt2"]["x"]
  //         }
  //     }
  // }
  let req = 0
  let rect = plank.oRect
  let width = Number(Number(rect.width * 100).toFixed())
  let height = Number(Number(rect.height * 100).toFixed())
  let slots = plank.slots
  let path = plank.oPath
  let pos_map = {
    b: 0,
    f: 0,
    l: 0,
    r: 0,
  }
  if (_dir && _dir != 'default') {
    for (let i = 0; i < slots.length; i++) {
      let slot = slots[i]
      let pt1 = slot.opt1
      let pt2 = slot.opt2
      let x1 = Number(pt1.x.toFixed(2))
      let y1 = Number(pt1.y.toFixed(2))
      let x2 = Number(pt2.x.toFixed(2))
      let y2 = Number(pt2.y.toFixed(2))
      if (x1 == x2) {
        if (x1 < width / 200) {
          pos_map.l = 1
        } else if (x1 > width / 200) {
          pos_map.r = 1
        }
      } else {
        if (y1 < height / 200) {
          pos_map.b = 1
        } else if (y1 > height / 200) {
          pos_map.f = 1
        }
      }
    }
    if (path && path.length) {
      let rect_poly = [
        { X: 0, Y: 0 },
        { X: width, Y: 0 },
        { X: width, Y: height },
        { X: 0, Y: height },
        { X: 0, Y: 0 },
      ]

      let ouline = path[0]
      let new_curve = ouline.map((item) => {
        return {
          X: Number((item.x * 100).toFixed()),
          Y: Number((item.y * 100).toFixed()),
        }
      })
      let cpr = new ClipperLib.Clipper()
      cpr.AddPath(rect_poly, ClipperLib.PolyType.ptSubject, true)
      cpr.AddPath(new_curve, ClipperLib.PolyType.ptClip, true)
      let solution_paths = new ClipperLib.Paths()
      cpr.Execute(
        ClipperLib.ClipType.ctDifference,
        solution_paths,
        ClipperLib.PolyFillType.pftEvenOdd,
        ClipperLib.PolyFillType.pftEvenOdd
      )
      if (solution_paths.length) {
        for (let i = 0; i < solution_paths.length; i++) {
          let solution_path = solution_paths[i]
          let min_x = 99999999
          let min_y = 99999999
          let max_x = -99999999
          let max_y = -99999999
          for (let j = 0; j < solution_path.length; j++) {
            let pt = solution_path[j]
            let x = Number(pt.X.toFixed(2))
            let y = Number(pt.Y.toFixed(2))
            if (min_x > x) {
              min_x = x
            }
            if (max_x < x) {
              max_x = x
            }
            if (min_y > y) {
              min_y = y
            }
            if (max_y < y) {
              max_y = y
            }
          }
          if (min_x == 0) {
            pos_map['l'] += 1.2
          }
          if (Math.abs(max_x - width) < 0.000001) {
            pos_map['r'] += 1.2
          }
          if (min_y == 0) {
            pos_map['b'] += 1.2
          }
          if (Math.abs(max_y - height) < 0.000001) {
            pos_map['f'] += 1.2
          }
        }
      }
    }
  }
  width /= 100
  height /= 100
  // 勾选前封边摆放方向
  if (is_front_edge_fixed) {
    if (model_front_edge) {
      let count =
        edge_dir_map[front_edge_fixed_direction] -
        edge_dir_map[model_front_edge]
      if (count < 0) {
        count += 4
      }
      req = count
    }
  } else if (
    Math.max(width, height) > long_edge_rotate_range ||
    (Math.max(width, height) <= long_edge_rotate_range &&
      ((!path && slots.length == 0) || !selected_dir))
  ) {
    if (
      ((width > height && outline_dir == 'Vertical') ||
        (width < height && outline_dir == 'Horizontal')) &&
      is_output56FDrillLayoutDir
    ) {
      // 旋转
      req = 1
    }
  } else {
    let count = 0
    let value = -1
    let dir_hash = ['b', 'r', 'f', 'l']
    for (let i = 0; i < 4; i++) {
      let i1 = 0
      let i2 = 0
      if (_dir == 'frontLeft') {
        i1 = 2
        i2 = 3
      } else if (_dir == 'backLeft') {
        i1 = 0
        i2 = 3
      } else if (_dir == 'frontRight') {
        i1 = 1
        i2 = 2
      } else if (_dir == 'backRight') {
        i1 = 0
        i2 = 1
      }
      let tmp_value =
        pos_map[dir_hash[(i + i1) % 4]] + pos_map[dir_hash[(i + i2) % 4]]
      if (tmp_value > value) {
        value = tmp_value
        count = i
      }
    }
    req = count
  }
  // xy互换情况下排版图逆时针旋转了90度，这里少转90度
  if (setting.xyReverse) {
    if (req == 0) {
      req = 3
    } else {
      req -= 1
    }
  }
  // 标签自动旋转90°
  if (setting.rotateLabelSetting) {
    let { criticalValue, rotateLabel, horizontal } = setting.rotateLabelSetting
    if (rotateLabel) {
      if (horizontal === 'H' && rect.width <= criticalValue) {
        req = req === 0 ? 3 : Math.abs((req - 1) % 4)
      }
      if (horizontal === 'V' && rect.height <= criticalValue) {
        req = req === 0 ? 3 : Math.abs((req - 1) % 4)
      }
      if (
        horizontal === 'HV' &&
        (rect.width <= criticalValue || rect.height <= criticalValue)
      ) {
        req = req === 0 ? 3 : Math.abs((req - 1) % 4)
      }
    }
  }
  return req
}
